$border-xs: 1px;
$border-sm: 2px;

$border-radius-xs: 5px;
$border-radius-sm: 10px;

@include generate-borders(
  (
    'grey1': $grey1
  ),
  (
    'none': 0,
    'xs': $border-xs,
    'sm': $border-sm
  )
);
@include generate-borders(
  (
    'grey2': $grey2
  ),
  (
    'none': 0,
    'xs': $border-xs,
    'sm': $border-sm
  )
);
@include generate-borders(
  (
    'grey3': $grey3
  ),
  (
    'none': 0,
    'xs': $border-xs,
    'sm': $border-sm
  )
);
@include generate-borders(
  (
    'grey6': $grey6
  ),
  (
    'none': 0,
    'xs': $border-xs,
    'sm': $border-sm
  )
);
@include generate-borders(
  (
    'black': $black
  ),
  (
    'none': 0,
    'xs': $border-xs,
    'sm': $border-sm
  )
);
@include generate-borders(
  (
    'green1': $green1
  ),
  (
    'none': 0,
    'xs': $border-xs,
    'sm': $border-sm
  )
);
@include generate-borders(
  (
    'blue3': $blue3
  ),
  (
    'none': 0,
    'xs': $border-xs,
    'sm': $border-sm
  )
);
@include generate-borders(
  (
    'blue4': $blue4
  ),
  (
    'none': 0,
    'xs': $border-xs,
    'sm': $border-sm
  )
);
@include generate-borders(
  (
    'blue5': $blue5
  ),
  (
    'none': 0,
    'xs': $border-xs,
    'sm': $border-sm
  )
);

@include css-generator(
  $className: 'border-radius',
  $cssProperty: border-radius,
  $values: (
    'none': 0px
  ),
  $breakpoints: (
    'xs': 0,
    'sm': 544px
  )
);
@include css-generator(
  $className: 'border-radius',
  $cssProperty: border-radius,
  $values: (
    'xs': $border-radius-xs
  ),
  $breakpoints: (
    'xs': 0,
    'sm': 544px
  )
);
@include css-generator(
  $className: 'border-radius',
  $cssProperty: border-radius,
  $values: (
    'sm': $border-radius-sm
  ),
  $breakpoints: (
    'xs': 0,
    'sm': 544px
  )
);

.left-only-border-radius {
  border-top-left-radius: $border-radius-sm;
  border-bottom-left-radius: $border-radius-sm;
}

.right-only-border-radius {
  border-top-right-radius: $border-radius-sm;
  border-bottom-right-radius: $border-radius-sm;
}
