$white: #fff;
$black: #000;

$grey1: #f2f2f2;
$grey2: #e9e9e9;
$grey3: #cdcdcd;
$grey4: #737373;
$grey5: #666666;
$grey6: #464646;
$grey7: #252525;
$grey8: #9a9a9a;
$grey9: #c3c3c3;
$grey10: #dbdbdb;

$blue1: rgba(0, 102, 204, 1);
$blue2: #aed7f7;
$blue3: #005b9c;
$blue4: #043b62;
$blue5: #4d9cde;
$blue6: #002639;

$red1: #d51114;
$red2: #f20a32;
$red3: #ee0d17;
$red4: #b01b21;

$red-disabled: #db0e16;

$green1: #00c988;
$green2: #069f06;

$yellow1: #ffe200;
$yellow2: #f8c129;

@include generate-colors(
  (
    'white': $white
  )
);
@include generate-colors(
  (
    'black': $black
  )
);

@include generate-colors(
  (
    'grey1': $grey1
  )
);
@include generate-colors(
  (
    'grey2': $grey2
  )
);
@include generate-colors(
  (
    'grey3': $grey3
  )
);
@include generate-colors(
  (
    'grey4': $grey4
  )
);
@include generate-colors(
  (
    'grey5': $grey5
  )
);
@include generate-colors(
  (
    'grey6': $grey6
  )
);
@include generate-colors(
  (
    'grey7': $grey7
  )
);
@include generate-colors(
  (
    'grey8': $grey8
  )
);
@include generate-colors(
  (
    'grey9': $grey9
  )
);
@include generate-colors(
  (
    'grey10': $grey10
  )
);

@include generate-colors(
  (
    'blue1': $blue1
  )
);
@include generate-colors(
  (
    'blue2': $blue2
  )
);
@include generate-colors(
  (
    'blue3': $blue3
  )
);
@include generate-colors(
  (
    'blue4': $blue4
  )
);
@include generate-colors(
  (
    'blue6': $blue6
  )
);

@include generate-colors(
  (
    'red1': $red1
  )
);
@include generate-colors(
  (
    'red2': $red2
  )
);
@include generate-colors(
  (
    'red3': $red3
  )
);
@include generate-colors(
  (
    'red4': $red4
  )
);

@include generate-colors(
  (
    'green1': $green1
  )
);
@include generate-colors(
  (
    'green2': $green2
  )
);

@include generate-colors(
  (
    'yellow1': $yellow1
  )
);
@include generate-colors(
  (
    'yellow2': $yellow2
  )
);

.background-transparent {
  background-color: rgba(255, 255, 255, 0) !important;
}
