.radio-group {
  .radio-label {
    background-color: #fff;
    color: #000;
    border: solid #999;
    border-width: 2px !important;
    padding: 10px 0px;
    font-weight: unset;
    border-radius: 10px;

    &:not(:last-child) {
      margin-right: 5%;
    }
  }

  .sticky {
    margin-right: 0 !important;
    border-width: 2px 1px 2px 1px !important;
    border-radius: 0px;

    &:first-of-type {
      border-left-width: 2px !important;
      border-right-width: 1px !important;
      border-radius: 10px 0px 0px 10px !important;
    }

    &:last-of-type {
      border-left-width: 1px !important;
      border-right-width: 2px !important;
      border-radius: 0px 10px 10px 0px !important;
    }
  }

  input[type='radio'] {
    display: none;

    &:checked + label {
      background-color: #005b9c !important;
      color: #fff !important;
      border-color: #005b9c !important;
    }
  }
}
