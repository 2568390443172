$margin-xs: 5px;
$margin-sm: 10px;
$margin-md: 20px;
$margin-lg: 30px;

@include generate-margins(
  $sizes: (
    'none': 0,
    'xs': $margin-xs,
    'sm': $margin-sm,
    'md': $margin-md,
    'lg': $margin-lg
  )
);

.margin-top-7 {
  margin-top: 7px;
}
