$padding-xs: 5px;
$padding-sm: 10px;
$padding-md: 20px;
$padding-lg: 30px;

$grid-breakpoints: (
  // Extra small screen / phone
  xs: 0,
  // Small screen / phone
  sm: 768px,
  // Medium screen / tablet
  md: 992px,
  // Large screen / desktop
  lg: 1200px
);

@include generate-paddings(
  $sizes: (
    'none': 0,
    'xs': $padding-xs,
    'sm': $padding-sm,
    'md': $padding-md,
    'lg': $padding-lg
  ),
  $breakpoints: $grid-breakpoints
);

.padding-left-40 {
  padding-left: 40px;
}

.padding-right-40 {
  padding-right: 40px;
}
