.ng-valid[required],
.ng-valid.required {
  border: 1px solid #42a948;
}

.ng-invalid:not(form):not(.inFormArray) {
  &.ng-dirty {
    border: 3px solid #a94442;
  }
}

.alert {
  padding: 5px !important;
}
