@font-face {
  font-family: 'Dosis-Bold';
  src: url('../assets/fonts/dosis/Dosis-Bold.ttf') format('truetype'), url('../assets/fonts/dosis/Dosis-Bold.eot');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Dosis-Medium';
  src: url('../assets/fonts/dosis/Dosis-Medium.ttf') format('truetype'), url('../assets/fonts/dosis/Dosis-Medium.eot');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'Dosis-SemiBold';
  src: url('../assets/fonts/dosis/Dosis-SemiBold.ttf') format('truetype'), url('../assets/fonts/dosis/Dosis-SemiBold.eot');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'OpenSans-Bold';
  src: url('../assets/fonts/openSans/OpenSans-Bold.ttf') format('truetype'),
    url('../assets/fonts/openSans/OpenSans-Bold.eot');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'OpenSans-SemiBold';
  src: url('../assets/fonts/openSans/OpenSans-Semibold.ttf'), url('../assets/fonts/openSans/OpenSans-Semibold.eot');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'OpenSans-Regular';
  src: url('../assets/fonts/openSans/OpenSans-Regular.ttf'), url('../assets/fonts/openSans/OpenSans-Regular.eot');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'OpenSans-Light';
  src: url('../assets/fonts/openSans/OpenSans-Light.ttf'), url('../assets/fonts/openSans/OpenSans-Light.eot');
  font-weight: 100;
  font-style: normal;
}
