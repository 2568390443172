.text-uppercase {
  text-transform: uppercase !important;
}
.text-capitalize {
  text-transform: capitalize !important;
}
.text-lowercase {
  text-transform: lowercase !important;
}
.text-italic {
  font-style: italic;
}

.cursor-hand {
  cursor: hand;
}

.tab-selected {
  background-color: #005b9c !important;
  color: #fff !important;
}

.sidebar-tab-selected {
  background-color: #005b9c !important;
}

.pagination-selected {
  background-color: white !important;
  border: 1px solid #005b9c !important;
}

.button-disabled {
  opacity: 33% !important;
}

.list-style-none {
  list-style: none;
}

.underlined-link {
  text-decoration: underline !important;
}
