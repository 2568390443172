@include generate-texts(
  $sizes: (
    '7': 7px,
    '8': 8px,
    '9': 9px,
    '10': 10px,
    '11': 11px,
    '12': 12px,
    '14': 14px,
    '16': 16px,
    '18': 18px,
    '20': 20px,
    '22': 22px,
    '24': 24px,
    '26': 26px,
    '28': 28px,
    '30': 30px,
    '35': 35px,
    '40': 40px
  )
);
@include generate-line-height(
  $sizes: (
    '16': 16px,
    '18': 18px,
    '20': 20px,
    '25': 25px,
    '26': 26px,
    '28': 28px,
    '30': 30px,
    '32': 32px,
    '34': 34px,
    '36': 36px,
    '38': 38px,
    '40': 40px,
    '42': 42px,
    '44': 44px,
    '46': 46px,
    '48': 48px,
    '50': 50px,
    '60': 60px,
    '75': 75px,
    '80': 80px,
    '85': 85px,
    '90': 90px
  )
);
@include generate-text-align(
  $sides: (
    center,
    left,
    right
  )
);

.dosis-bold {
  font-family: 'Dosis-Bold';
}

.dosis-medium {
  font-family: 'Dosis-Medium';
}

.dosis-semibold {
  font-family: 'Dosis-SemiBold';
}

.openSans-bold {
  font-family: OpenSans-Bold;
}

.openSans-semibold {
  font-family: OpenSans-SemiBold;
}

.openSans-regular {
  font-family: OpenSans-Regular;
}

.openSans-light {
  font-family: OpenSans-Light;
}
.not-bold {
  font-weight: normal !important;
}
